<template>
  <div
    class="relative"
  >
    <div
      id="home-banner-background-image"
      class="absolute inset-0 overflow-hidden z-0"
    >
      <NuxtPicture
        src="images/component/homeBanner/background.png"
        sizes="100vw mobile:740px tablet:1080px laptop:1600px desktop:1920px"
      />
    </div>

    <div
      id="home-banner-content"
      class="relative z-10"
    >
      <div
        class="my-32 mx-auto w-content-item text-center text-white"
      >
        <div
          id="home-banner-texts"
          class="laptop:w-2/3 mx-auto"
        >
          <span
            class="b1 font-bold uppercase tracking-[.23em] text-shadow"
          >
            {{ t('jc.component.hero.catchphrase') }}
          </span>
          <h1
            class="h0 mt-8 text-shadow-light"
          >
            {{ t('jc.component.hero.title') }}
          </h1>
          <span
            class="h2 mt-4 font-bold uppercase tracking-[.23em] text-shadow"
          >
            {{ t('jc.component.hero.version', { version: versionInfo?.displayName ?? "?" }) }}
          </span>
        </div>

        <div
          id="home-banner-buttons"
          class="grid grid-rows-[1fr_1fr_1fr] laptop:grid-rows-1 laptop:grid-cols-[1fr_1fr_1fr] gap-8 justify-around
            mt-8 my-8 laptop:my-16"
        >
          <NuxtLink
            :to="config.public.discordUrl"
            target="_blank"
            class="bg-secondary hover:bg-secondary-highlighted active:bg-secondary-selected text-white
              text-center rounded py-2.5 px-8 mx-3 laptop:ml-auto my-auto rounded-full text-[1.2em] font-semibold"
          >
            <font-awesome-icon
              :icon="{ prefix: 'fab', iconName: 'discord' }"
              class="mr-3"
            />
            <span>{{ t('jc.component.hero.discordButton.label') }}</span>
          </NuxtLink>
          <button
            :title="t('jc.component.hero.minecraftButton.title')"
            class="bg-primary hover:bg-primary-highlighted active:bg-primary-selected text-white
              text-center rounded py-4 px-6 rounded-full h2 font-semibold"
            @click="() => copyMinecraftIp()"
            type="button"
          >
            {{ t('jc.component.hero.minecraftButton.label') }}
          </button>
          <NuxtLink
            :to="config.public.minecraftServerEuUrl + username"
            target="_blank"
            class="bg-secondary hover:bg-secondary-highlighted active:bg-secondary-selected text-white
              text-center rounded py-2.5 px-8 mx-3 laptop:mr-auto my-auto rounded-full text-[1.2em] font-semibold"
          >
            <font-awesome-icon
              icon="check-to-slot"
              class="mr-3"
            />
            <span>{{ t('jc.component.hero.voteButton.label') }}</span>
          </NuxtLink>
        </div>
      </div>

      <span
        class="block bg-primary w-full min-h-[10px] laptop:min-h-[15px]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {type ServerVersion} from "~/utils/charon-types";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const { t } = useI18n()
const config = useRuntimeConfig()
const charon = useCharon()
const me = useMe()
const copyMinecraftIp = useCopyMinecraftIp()

const username = computed(() => me.value?.name || '')

const { data: versionInfo } = useAsyncData<ServerVersion>(
  `server-version`,
  () => charon<ServerVersion>(`/server/version`),
  {
    lazy: true,
    server: true
  }
)
</script>

<style scoped lang="postcss">
:deep(img) {
  @apply w-full h-full object-cover object-top
}
</style>
